import * as React from "react"
import styled from "styled-components"
import Layout from '../components/Layout'

const items = [
  { id: 0, text: "Set up gatsbyjs starter with styled-components and mdx", done: true },
  { id: 1, text: "Set up blog post feed on home page", done: true },
  { id: 2, text: "Set up todos page", done: true },
  { id: 3, text: "Publish subdomain", done: true },
  { id: 4, text: "Write 2020 year in review post", done: true },
  { id: 5, text: "Horizontal nav", done: true },
  { id: 6, text: "Make separate pages for blog posts", done: true },
  { id: 7, text: "Move blog from front page to dedicated page", done: true },
  { id: 8, text: "Basic resume page", done: true },
  { id: 9, text: "System theme (dark mode)", done: true },
  { id: 10, text: "Write something", done: true },
  { id: 11, text: "improve mobile nav", done: false },
]

const Task = styled.li`
  text-decoration: ${props => props.done ? 'line-through' : 'none'};
`

const TodoPage = () => {
  return (
    <Layout pageTitle="Todos for fabio.desousa.studio">
      I'm building this thing in public, lots of things will be half-finished and fully live.
      <ul>
        {items.sort((a, b) => b - a).map(item => (
          <Task done={item.done} key={item.id}>
            {item.text}
          </Task>
        ))}
      </ul>
    </Layout>
  )
}


export default TodoPage
